import React, { useEffect, useState }  from 'react'
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Tooltip as Mensaje } from "react-tooltip";
import { setTorneoEspecificoCookie } from '../controllers/cookies';
import { cantidadDeportistas, traerTorneosTemporales } from '../controllers/configuracionFirebase';
import { coloresFechaCierre, fetchData, time, timeLocal } from '../controllers/tiempoLimiteInscripcion';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Mapa from './Mapa'
import { Message } from 'primereact/message';
import { Badge } from 'primereact/badge';
import { loadGoogleMapsAPI } from '../controllers/apiGoogleMaps';
import ReactPlayer from 'react-player';
import Conctatenos from './Conctatenos';

const Torneos = ({
  setIdTorneoParticipando,
  setParticipando,
  torneos,
  setTorneos,
  setTorneoEspecifico,
  usuario}) => {

  const [ cantNadadores, setCantNadadores ] = useState([]);
  const [ counters, setCounters ] = useState([]);
  const [ dialogMapa, setDialogMapa ] = useState(false);
  const [ dialogChampions, setDialogChampions ] = useState(false);
  const [ mostrarMensaje, setMostrarMensaje ] = useState(false);
  const [ estadoApi, setEstadoApi ] = useState(false);
  const [ tituloEvento, setTituloEvento ] = useState('');
  const [ detallesMapa, setDetallesMapa ] = useState({distancia: null, tiempo: null});
  const [ direccion, setDireccion ] = useState('');
  const [ contadorLlamado, setContadorLlamado ] = useState(0);

  function participar(torneo){
    setTorneoEspecifico(torneo);
    setIdTorneoParticipando(torneo.id);
    setParticipando(true);
    //setTorneoEspecificoCookie('idTorneo', torneo.id);
  }

  function arreglarFechaTorneo(fecha){
    const fechaTorneo = new Date(fecha);
    fechaTorneo.setDate(fechaTorneo.getDate() + 1);
    return fechaTorneo.toLocaleString('es-ES',{ 
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'})
  }

  function irAlMapa(title, address){
    if(contadorLlamado === 0){
      loadGoogleMapsAPI().then(()=>{
        setContadorLlamado(contadorLlamado + 1);
      }).finally(() =>{
        setEstadoApi(true);
        setDialogMapa(true);
        setTituloEvento(title);
        setDireccion(address);
      })
    }

    if(estadoApi){
      setDialogMapa(true);
      setTituloEvento(title);
      setDireccion(address)
    }
  }

  const content = (
    <div className="d-flex align-items-center">
        <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
        <div className="ms-2 text-danger">Para ver más detalles, por favor active la función de ubicación en su navegador.</div>
    </div>
  );

  const contentTimeCierre = (torneo, indexTorneo) => {
    return <div className="d-flex align-items-center">
        <div className={`ms-2`}>
          {!torneo.inscripcionesCerradas ? `Inscripciones ${torneo.fechaInscripciones}` : 'Inscripciones Cerradas'} <br />
          <div id={`contenedor-fecha${indexTorneo}`}>
              <span style={{textShadow: '0 0 1px black'}}>Faltan </span>
              <span id={`dia${indexTorneo}`} style={{textShadow: '0 0 1px black'}}>00</span>
              <span id={`dia-hora${indexTorneo}`} style={{textShadow: '0 0 1px black'}}></span>
            </div>
            <div id={`contenedor-diaLocal${indexTorneo}`}>
              <span className='fs-4' id={`diaLocal${indexTorneo}`}>00</span><span className='fs-5'>D : </span>
              <span className='fs-4' id={`horaLocal${indexTorneo}`}>00</span><span className='fs-5'>H : </span>
              <span className='fs-4' id={`minutoLocal${indexTorneo}`}>00</span><span className='fs-5'>M : </span>
              <span className='fs-4' id={`segundoLocal${indexTorneo}`}>00</span><span className='fs-5'>S</span> 
            </div>
          </div>
    </div>
  };

  const contentDetallesMapa = (
    <div className="d-flex align-items-center">
        <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
        <div className="ms-2 text-danger">{detallesMapa.distancia && detallesMapa.tiempo ? `Estás a ${detallesMapa.distancia} de distancia, llegando en ${detallesMapa.tiempo}.` : ''}</div>
    </div>
  );

  useEffect(()=>{
    traerTorneosTemporales(setTorneos);
  },[])

  useEffect(() => {
    const aux = [];

    async function obtenerCantidadesDeportistas() {
      for (const tor of torneos) {
        const cant = await cantidadDeportistas(tor.id);
        aux.push(cant);
      }
      setCantNadadores(aux);
    }

    obtenerCantidadesDeportistas();
  }, [torneos, setCantNadadores])

  useEffect(()=>{
    fetchData().then((fech)=>{
      torneos.forEach((tor, indexTor)=>{
        time(tor.fechaInscripciones, tor.id, indexTor, fech);
        timeLocal(tor.fechaInscripciones, indexTor);
      })
    })
  },[torneos])

  useEffect(() => {
    const animationDuration = 500; // Duración de la animación en milisegundos

    const animateCounters = () => {
      let startTimestamp;
      const updateCounters = (timestamp) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }

        const progress = (timestamp - startTimestamp) / animationDuration;
        const updatedCounters = cantNadadores.map((value, index) => {
          if (progress >= 1) {
            return value;
          }
          return Math.floor(progress * value);
        });

        setCounters(updatedCounters);

        if (progress < 1) {
          requestAnimationFrame(updateCounters);
        }
      };

      requestAnimationFrame(updateCounters);
    };

    animateCounters();
  }, [cantNadadores]);

  return (
    <>
      <div className='ps-5 pe-5 pt-2 pb-4'>
        {torneos.length !== 0 ? (
          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-center align-items-start'>
            <motion.div
              className={`position-relative card m-2 pt-2 col shadow card-torneo`}
              style={{ width: "22rem" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: `0.5` }}
            >
              <span className='linea linea-left'></span>
              <span className='linea linea-right'></span>
              <span className='linea linea-top'></span>
              <span className='linea linea-bottom'></span>
              <ul className="circles-card-torneo">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center position-relative"
                >
                  <p
                    className='mb-0'
                    style={{ fontSize: "15px", fontFamily: "Calistoga" }}
                  >
                    Publicidad
                  </p>
                </div>
              </div>
              <img
                src={require("../img/mascotapws.webp")}
                className="card-img-top m-auto position-relative"
                alt="imagen del torneo"
                style={{ width: "200px", filter: "drop-shadow(-1px 1px 2px black)"}}
              />
              <div className="card-body p-0 text-center position-relative">
                <h4 className="card-title position-relative mt-2 mb-0" style={{ fontFamily: "Alkatra" }}>
                  INICIATIVA
                </h4>
                <h5 className="card-title position-relative" style={{ fontFamily: "Alkatra" }}>
                  PROWEBSPORTS CHAMPIONS
                </h5>
                <p
                  className="card-text"
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    textAlign: "justify",
                  }}
                >
                  ¡Invita a tus atletas a ser parte de PROWEBSPORTS CHAMPIONS! Este nuevo proyecto premia el talento y esfuerzo en natación. Al afiliarse por COP 50,000 al año, los deportistas no solo competirán en diversas pruebas, sino que también podrán ganar atractivos premios en efectivo. Completa el formulario y descubre cómo pueden beneficiarse tus nadadores. ¡No te pierdas esta oportunidad!
                </p>
                <Button icon='pi pi-comments' type="button" label='Llenar Formulario' className="btn btn-primary mb-2" onClick={() => setDialogChampions(true)}></Button>
              </div>
            </motion.div>
            {torneos.map((torneo, indexTorneo) => {
                return (
                  <motion.div
                    className={`position-relative card m-2 pt-2 col shadow card-torneo ${torneo.inscripcionesCerradas ? 'animacion-rojo' : ''}`}
                    style={{ width: "22rem" }}
                    key={indexTorneo}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: `0.${indexTorneo}5` }}
                    id={`card-torneo${indexTorneo}`}
                  >
                    <span className='linea linea-left'></span>
                    <span className='linea linea-right'></span>
                    <span className='linea linea-top'></span>
                    <span className='linea linea-bottom'></span>
                    <ul className="circles-card-torneo">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <div className="d-flex justify-content-between">
                      <div
                        className="d-flex align-items-center position-relative" id={`cantNadadores${indexTorneo}`}
                      >
                        <img
                          className="ms-2"
                          style={{ width: "18px" }}
                          src={require("../img/icon-usuario-curso.webp")}
                          alt="icono del usuario curso"
                        />
                        <motion.p
                          className='mb-0'
                          style={{ fontSize: "18px", fontFamily: "Calistoga" }}
                        >
                          {cantNadadores[indexTorneo] ? counters[indexTorneo] ? Math.floor(counters[indexTorneo]) : 0 : 0}
                        </motion.p>
                      </div>
                    </div>
                    <Message
                      style={{
                          border: 'solid #696cff',
                          borderWidth: '0 0 0 6px',
                          color: '#696cff',
                          position: 'relative'
                      }}
                      id={`messageFechaCierre${indexTorneo}`}
                      className={`justify-content-center mb-1`}
                      severity={coloresFechaCierre(torneo.fechaInscripciones)}
                      content={contentTimeCierre(torneo, indexTorneo)}
                    />
                    <Mensaje
                      anchorId={`cantNadadores${indexTorneo}`}
                      place="bottom"
                      content={`Inscritos`}
                      className="bg-primary shadow"
                      style={{ zIndex: 1}}
                    />
                    {torneo.image !== "" ? (
                    <img
                      src={torneo.image}
                      className="card-img-top w-100 position-relative"
                      alt="imagen del torneo"
                    />
                    ) : (
                      <img
                        src={require("../img/img_logo.png")}
                        className="card-img-top m-auto position-relative"
                        alt="imagen del torneo"
                        style={{ width: "200px", filter: "drop-shadow(-1px 1px 2px black)"}}
                      />
                    )}
                    <div className="card-body p-0 text-center position-relative">
                      <h5 className="card-title position-relative" style={{ fontFamily: "Alkatra" }}>
                        {torneo.titulo}
                      </h5>
                      {torneo.convocatoria !== '' ? (
                        <>
                          <Link to={torneo.convocatoria} target='_blank'>
                            <i className='pi pi-file-pdf text-danger btn btn-light mb-2 me-2 fs-4' id={`conv${indexTorneo}`}></i>
                          </Link>
                        </>
                      ):(<></>)}
                      <Link to="/inscripciones" onClick={() => participar(torneo)}>
                        <Button type='button' icon='pi pi-users' className='btn btn-success mb-2' label="¡Quiero Participar!" style={{borderRadius: '10px'}}>
                        </Button>
                      </Link>
                      <i type='button' id={`ubi${indexTorneo}`} className='pi pi-map-marker btn btn-light mb-2 ms-2 p-overlay-badge fs-4 position-relative' style={{borderRadius: '10px', color: 'rgb(184,135,0)'}} onClick={() => irAlMapa(torneo.titulo, torneo.direccion)}>
                      </i>
                      <Mensaje
                        anchorId={`ubi${indexTorneo}`}
                        place="bottom"
                        content={`Ubicación`}
                        className="bg-primary shadow"
                        style={{ zIndex: 1}}
                      />
                      <Mensaje
                        anchorId={`conv${indexTorneo}`}
                        place="bottom"
                        content={`Convocatoria`}
                        className="bg-primary shadow"
                        style={{ zIndex: 1}}
                      />
                    </div>
                    <div className='card-footer'>
                      <div className="accordion accordion-flush" id={`accordionFlushExample${indexTorneo}`}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={`flush-heading${indexTorneo}`}>
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${indexTorneo}`} aria-expanded="false" aria-controls={`flush-collapse${indexTorneo}`}>
                                Información Del Evento.
                              </button>
                            </h2>
                            <div id={`flush-collapse${indexTorneo}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${indexTorneo}`} data-bs-parent={`#accordionFlushExample${indexTorneo}`}>
                              <div className="accordion-body p-0">
                              <table className='table table-bordered'>
                                <thead>
                                  <tr>
                                    <th>$ Club</th>
                                    <th>$ Deportista</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>${parseInt(torneo.valorClub).toLocaleString()}</td>
                                    <td>${parseInt(torneo.valorDeportista).toLocaleString()}</td>
                                  </tr>
                                  <tr>
                                    <th colSpan={2}>Fecha Evento</th>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}>{arreglarFechaTorneo(torneo.fechaTorneo)}</td>
                                  </tr>
                                  {torneo.descripcion !== '' ?(
                                    <>
                                      <tr>
                                        <th colSpan={2}>Descripción</th>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>{torneo.descripcion}</td>
                                      </tr>
                                    </>
                                  ):(
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                );
              })
            }
            <motion.div
              className={`position-relative card m-2 ps-0 pe-0 pt-2 col shadow card-torneo animacion-rojo`}
              style={{ width: "22rem" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: '0.25' }}
              id={`card-torneo100`}
            >
              <span className='linea linea-left'></span>
              <span className='linea linea-right'></span>
              <span className='linea linea-top'></span>
              <span className='linea linea-bottom'></span>
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center" id={`cantNadadores100`}
                >
                  <img
                    className="ms-2"
                    style={{ width: "18px" }}
                    src={require("../img/icon-usuario-curso.webp")}
                    alt="icono del usuario curso"
                  />
                  <motion.p
                    className='mb-0'
                    style={{ fontSize: "18px", fontFamily: "Calistoga" }}
                  >
                    {218}
                  </motion.p>
                </div>
                <p className="me-2 mb-0"><small className="text-muted">2 De Julio 2023</small></p>
              </div>
              <Mensaje
                anchorId={`cantNadadores100`}
                place="bottom"
                content={`Inscritos`}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              <div className="card-body p-0 m-0 text-center">
                <ReactPlayer
                  url="https://youtu.be/zlkRWWCvPg8"
                  width="100"
                  controls
                  playing
                  loop
                />
                <h5 className="card-title mt-3" style={{ fontFamily: "Alkatra" }}>
                  {'V TORNEO REGIONAL DE NATACIÓN'}
                </h5>
                  <Link to={require('../archivos/resultados-tiempos.pdf')} target='_blank' className='m-0' style={{textDecoration: 'none'}}>
                    <Button type='button' icon='pi pi-file-pdf' className='btn btn-success mb-2' label="Resultados" style={{borderRadius: '10px'}}></Button>
                  </Link>
              </div>
            </motion.div>
            <motion.div
              className={`position-relative card m-2 ps-0 pe-0 pt-2 col shadow card-torneo animacion-rojo`}
              style={{ width: "22rem" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: '0.35' }}
              id={`card-torneo101`}
            >
              <span className='linea linea-left'></span>
              <span className='linea linea-right'></span>
              <span className='linea linea-top'></span>
              <span className='linea linea-bottom'></span>
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center" id={`cantNadadores101`}
                >
                  <img
                    className="ms-2"
                    style={{ width: "18px" }}
                    src={require("../img/icon-usuario-curso.webp")}
                    alt="icono del usuario curso"
                  />
                  <motion.p
                    className='mb-0'
                    style={{ fontSize: "18px", fontFamily: "Calistoga" }}
                  >
                    {160}
                  </motion.p>
                </div>
                <p className="me-2 mb-0"><small className="text-muted">4 De Junio 2022</small></p>
              </div>
              <Mensaje
                anchorId={`cantNadadores101`}
                place="bottom"
                content={`Inscritos`}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              <div className="card-body p-0 m-0 text-center">
                <div id="carouselExampleDark" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="6" aria-label="Slide 7"></button>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="7" aria-label="Slide 8"></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="10000">
                      <img src={require('../img/foto-1.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item" data-bs-interval="2000">
                      <img src={require('../img/foto-2.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('../img/foto-3.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('../img/foto-4.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('../img/foto-5.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('../img/foto-6.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('../img/foto-7.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                    <div className="carousel-item">
                      <img src={require('../img/foto-8.webp')} className="d-block w-100" alt="imagen del app web orcas valle"/>
                    </div>
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <h5 className="card-title mt-3" style={{ fontFamily: "Alkatra" }}>
                  {'IV TORNEO REGIONAL DE NATACIÓN'}
                </h5>
              </div>
            </motion.div>
          </div>
        ):(
          <motion.div className='mt-2 pb-2 m-auto bg-light shadow' style={{borderRadius: '10px', maxWidth: '850px'}}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1}}
          >
            <img className='w-50' src={require('../img/img-logo-swimmingt.webp')} alt="Logo prowebsports" />
            <h1>¡BIENVENIDO!</h1>
            <p>No tenemos torneos registrados aún.</p>
          </motion.div>
        )}
      </div>
      <Dialog visible={dialogMapa} style={{ width: '600px', height: '600px' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={`${tituloEvento}`} modal className="p-fluid" onHide={() => setDialogMapa(false)}>
        {mostrarMensaje ? (
        <Message
          style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
              color: '#696cff'
          }}
          className="border-danger justify-content-center mb-1"
          severity="error"
          content={content}
        />):(
          <Message
          style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
              color: '#696cff'
          }}
          className="border-success justify-content-center mb-1"
          severity="success"
          content={contentDetallesMapa}
          />
        )}
        {<Mapa
        direccion={direccion} 
        setMostrarMensaje={setMostrarMensaje} 
        tituloEvento={tituloEvento}
        setDetallesMapa={setDetallesMapa}
        usuario={usuario}/>}
      </Dialog>
      <div className='contactenos' style={{height: '354px'}}>
        <Conctatenos/>
      </div>
      <Dialog visible={dialogChampions} style={{ width: '50em', height: '2397px' }} breakpoints={{ '700px': '100%' }} header="Encuesta PROWEBSPORTS CHAMPIONS" modal className="p-fluid" onHide={() => setDialogChampions(false)}>
        <div className="field">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5SsAEAHqX1_MeGT1cPRyXGzNJGO7fDIW4e9LfWeCzjQgw-Q/viewform?embedded=true" width="100%" height="2550" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
    </>
  )
}

export default Torneos