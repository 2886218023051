export const loadGoogleMapsAPI = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('googleMaps');

    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = 'googleMaps';
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAYNSywNBKShlspq0X4ITBHmVkm8a0GVZA&libraries=places`;
    script.defer = true;
    script.async = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error('No se pudo cargar la API de Google Maps.'));
    };

    document.body.appendChild(script);
  });
};